<template>
  <div aria-hidden="true">
    <h3 class="title placeholder-glow">
      <span class="placeholder placeholder-sm col-2" />
    </h3>
    <h6 class="title placeholder-glow">
      <span class="placeholder placeholder-sm  col-10" />
    </h6>
    <p class="placeholder-glow">
      <span class="placeholder placeholder-lg col-4" />
      <span class="placeholder placeholder-lg col-2 bg-danger" />
      <span class="placeholder placeholder-lg col-4" />
    </p>
  </div>
</template>

<style scoped lang="scss">
.title {
  margin:0;
  text-align: left;
}
</style>