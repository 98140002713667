<template>
  <a
    class="btn link-mpg do-not-share"
    :href="mpgUrl"
    target="_blank"
  >
    Voir sur MPG
  </a>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  matchId: {
    type: String,
    required: true,
  },
  divisionId: {
    type: String,
    required: true,
  },
});

const mpgUrl = computed(() => {
  return `https://mpg.football/mpg-match/league/${props.divisionId}/${props.matchId}`;
});
</script>

<style scoped lang="scss">
.link-mpg {
  position: relative;
  background-color: #4dd04e;
  color: #333;
  &:hover {
    background-color: #4dd04db2;
  }
}
</style>