import { Match } from "@/models/match/Match";

/**
 * Renvoi un nouveau match en ajoutant la note moyenne des joueurs
 * dont le match n'a pas encore commencé
 * @param {Match} match le match à modifier
 * @param {*} championshipMatches matchs du championnat
 * @returns {Match} le match avec les moyennes pour les joueurs
 */
const setMatchPlayersStatus = (match, championshipMatches) => {
  const matchCopy = new Match(match);

  const { liveTeams, unplayedTeams } = getTeamStatus(championshipMatches);
  const liveTeamsIds = liveTeams.map((team) => team.clubId);
  const unplayedTeamsIds = unplayedTeams.map((team) => team.clubId);

  const { subOff: substituedPlayersIds, subOn: entergingPlayersIds } = getSubstituedPlayers(liveTeams);
  const liveSubstitutePlayersIds = getLiveSubstitutePlayers(liveTeams, entergingPlayersIds);

  setTeamPlayersStatus(matchCopy.homeTeam, liveTeamsIds, unplayedTeamsIds, substituedPlayersIds, liveSubstitutePlayersIds);
  setTeamPlayersStatus(matchCopy.awayTeam, liveTeamsIds, unplayedTeamsIds, substituedPlayersIds, liveSubstitutePlayersIds);

  return matchCopy;
};

const getTeamStatus = (championshipMatches) => {
  const startedPeriods = ["firstHalf", "halfTime", "secondHalf"];
  const liveTeams = [];
  const unplayedTeams = [];
  Object.values(championshipMatches).forEach((match) => {
    if (match.period && startedPeriods.includes(match.period)) {
      liveTeams.push(match.home);
      liveTeams.push(match.away);
    }
    if (! match.period) {
      unplayedTeams.push(match.home);
      unplayedTeams.push(match.away);
    }
  });
  return {
    liveTeams,
    unplayedTeams,
  };
};

const getSubstituedPlayers = (liveTeams) => {
  const substitutedPlayers = [];
  const enteringPlayers = [];
  liveTeams.forEach((team) => {
    team.substitutions.forEach((substitution) => {
      substitutedPlayers.push(substitution.subOff);
      enteringPlayers.push(substitution.subOn);
    });
  });
  return {
    subOff: substitutedPlayers,
    subOn: enteringPlayers,
  };
};

const getLiveSubstitutePlayers = (liveTeams, entergingPlayersIds) => {
  const liveSubstitutePlayersIds = {};
  liveTeams.forEach((team) => {
    const teamSubstitutes = [];
    Object.values(team.players).forEach((player) => {
      if (player.sub && ! entergingPlayersIds.includes(player.id)) {
        teamSubstitutes.push(player.id);
      }
    });
    if (teamSubstitutes.length > 0) {
      liveSubstitutePlayersIds[team.clubId] = teamSubstitutes;
    }
  });
  return liveSubstitutePlayersIds;
};

const setTeamPlayersStatus = (team, liveTeams, unplayedTeamsIds, substituedPlayersIds, liveSubstitutePlayersIds) => {
  team.pitchPlayers.forEach((player) => {
    setTeamPlayerStatus(player, liveTeams, unplayedTeamsIds, substituedPlayersIds, liveSubstitutePlayersIds);
  });
  team.benchPlayers.forEach((player) => {
    setTeamPlayerStatus(player, liveTeams, unplayedTeamsIds, substituedPlayersIds, liveSubstitutePlayersIds);
  });
};

const setTeamPlayerStatus = (player, liveTeams, unplayedTeamsIds, substituedPlayersIds, liveSubstitutePlayersIds) => {
  const unplayedTeamsIdsMerge = Object.values(unplayedTeamsIds).flat(1);
  player.notPlayed = isPlayerNotPlayed(unplayedTeamsIdsMerge, player);

  if (! player.notPlayed) {
    const liveSubstitutePlayersIdsMerge = Object.values(liveSubstitutePlayersIds).flat(1);
    player.isLiveRating = isPlayerLive(liveTeams, player, substituedPlayersIds);
    if (player.isLiveRating) {
      player.isLiveSubstitute = isPlayerLiveSubstitute(liveSubstitutePlayersIdsMerge, player);
      player.isLiveSubstitued = substituedPlayersIds.includes(player.playerId);

      if (player.isLiveSubstitute && ! player.isLiveSubstitued) {
        player.isFinalSubstitute = liveSubstitutePlayersIds[player.clubId].length >= 5;
        player.isLiveRating = ! player.isFinalSubstitute;
      }
    }
  }
};

const isPlayerNotPlayed = (unplayedTeamsIds, player) => {
  return unplayedTeamsIds.includes(player.clubId);
};
const isPlayerLive = (liveTeams, player, substituedPlayersIds) => {
  return liveTeams.includes(player.clubId) && ! substituedPlayersIds.includes(player.playerId);
};

const isPlayerLiveSubstitute = (liveSubstitutePlayersIds, player) => {
  return liveSubstitutePlayersIds.includes(player.playerId);
};

export { setMatchPlayersStatus };