<template>
  <section>
    <the-header />

    <main>
      <transition mode="out-in">
        <section v-if="!isConnected" class="connection">
          <mpg-connection-form />
        </section>
        <section v-else-if="loginEnded" class="content">
          <live-rating-disclaimer v-if="!haveLiveRating" />
          <live-display v-else />
        </section>
      </transition>

      <the-footer />
    </main>
  </section>
</template>

<script setup>
import TheHeader from "@/components/header/TheHeader.vue";
import TheFooter from "@/components/footer/TheFooter.vue";
import LiveRatingDisclaimer from "@/components/disclaimers/LiveRatingDisclaimer.vue";
import LiveDisplay from "@/components/live/LiveDisplay.vue";
import MpgConnectionForm from "@/components/login/MpgConnectionForm.vue";

import { useMPG } from "@/use/useMPG";
const { isConnected, loginEnded, haveLiveRating } = useMPG();
</script>

<style scoped lang="scss">
body {
  margin: 0;
}
#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

main {
  padding: 5vh 0;

  .connection, .content {
    display: flex;
    justify-content: center;
  }
}

/**
 * Transitions
 */
 .v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>