<template>
  <div class="card mb-3" style="max-width: 50vw; min-height: 20vh;">
    <div class="row g-0">
      <div class="col-md-4">
        <img src="/img/notes-live.png" class="img-fluid rounded-start" alt="Bannière MPG">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title">
            Vous n'avez pas accès aux notes Live
          </h5>
          <div class="card-text">
            <p class="disclamer">
              Vous n'avez pas l'option "Notes Live". Cette option est necessaire pour accèder à MPG Calculator :
              <a href="https://mpg.football/shop/offers?modal=offerItem&offer=liveRating" target="_blank">Activer l'option</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>